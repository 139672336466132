.app-main-div {
  background: linear-gradient(184deg, #e3e5e6 0%, #fff 100%);
  animation: transitionIn 3s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-5deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floating {
  0% {
    transform: translate(0%, 0%) rotate(0deg);
  }
  50% {
    transform: translate(5%, 15%) rotate(2deg);
  }
  75% {
    transform: translate(20%, 5%) rotate(3deg);
  }

  100% {
    transform: translate(0%, 0%) rotate(1deg);
  }
}
.my-img {
  position: relative;
  display: block;
  justify-self: center;
  align-self: start;
  width: 4.5rem;
}

.word-wrapper {
  white-space: nowrap;
}
.header-text {
  color: rgb(35, 33, 33);
  margin: 2%;
  font-weight: bold;
}

.motion-container {
  /*background-image: url('./images/Keyboard11.jpg');*/
  background-color: rgb(191, 193, 195);
  color: #0b7276;
}
.js-logo {
  width: 70px;
  height: 80px;
  animation: fadeInFromTop 0.7s linear forwards, floating ease 3.8s infinite;
}
.css-logo {
  width: 70px;
  height: 80px;
  animation: fadeInFromTop 2s linear forwards, floating ease 4.5s infinite;
}
.html-logo {
  width: 70px;
  height: 80px;
  animation: fadeInFromTop 0.5s linear forwards, floating ease 4s infinite;
}
.bootstrap-logo {
  width: 80px;
  height: 90px;
  padding-top: 8px;
  animation: fadeInFromTop 0.2s linear both, floating ease 3.5s infinite;
}
.github-logo {
  width: 100px;
  height: 80px;
  padding-bottom: 10px;
  animation: fadeInFromTop 0.9s linear forwards, floating ease 2.9s infinite;
}

.nodejs-logo {
  width: 100px;
  height: 59px;
  animation: fadeInFromTop 0.7s linear forwards, floating ease 2.7s infinite;
}

.react-logo {
  width: 100px;
  height: 90px;
  animation: fadeInFromTop 1s linear backwards, floating ease 4.3s infinite;
}

.python-logo {
  width: 90px;
  height: 75px;
  animation: fadeInFromTop 1.1s linear backwards, floating ease 3.1s infinite;
}

.django-logo {
  width: 150px;
  height: 70px;
  animation: fadeInFromTop 1.2s linear backwards, floating ease 3.3s infinite;
}
.myImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  width: 50%;
  border-radius: 8px;
  opacity: 80%;
}

.myImage:hover {
  opacity: 100%;
}

.navbar-main {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  letter-spacing: 3px;
}
.nav-link:hover {
  font-size: 1.1rem;
}

body {
  font-family: "Arvo", serif;
  text-shadow: 4px 4px 4px #aaa;
}

#scrollTop {
  position: fixed;
  width: 30px;
  height: 30px;
  left: 88%;
  bottom: 40px;
  z-index: 1;
  cursor: pointer;
  font-size: 5rem;
  color: #0b7276;
}


.contact-form-div {
  border-radius: 3%;
  }

  #contact{
    background-color: #e2ede224;
    border-radius: 3%;
  }
.btn-success {
  --bs-btn-color: #050505;
  --bs-btn-bg:  #e0edeb;
  --bs-btn-border-color: #79a792;
  --bs-btn-hover-color: #dcebdc;
  --bs-btn-hover-bg: #416149;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #92b5a5;

  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #a3c0b2;
  --bs-btn-disabled-border-color: #8dc1a9;
  border: none;
}
.btn-danger {
  --bs-btn-color: #111111;
  --bs-btn-bg: #e0edeb;
  --bs-btn-border-color: #a79e9f;
  --bs-btn-hover-color: #f4f1f1;
  --bs-btn-hover-bg: #6b6464;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #af989a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ac888b;
  border: none;
}
.error {
  color: #696363c7;
  background: #eff5f7;
}
.form-control.is-invalid {
  border-color: #171314;
}


/* Add this CSS to create the floating label effect */
